import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 className="title is-1">LGBTQ Care</h1>
    <p>{`We believe that all individuals are due fair and equal care and support irrespective of one’s sexual orientation or gender. We at PCMH Restore Health strive to provide anonymity and optimal care without bias. One of our major objectives is to address the health care needs of the LGBTQ  community.`}</p>
    <p>{`​`}</p>
    <p>{`The challenges and barriers to health care can result in poor quality of life. Discrimination, inequality, stigma, violence, abuse are a few of the challenges faced by the LGBTQ community. While some of the health concerns faced by the community are similar to the rest of the population, some occur at a higher rate and some are unique to the community.  In addition to these, deprivation of basic human rights such as health care and support can take a toil on physical as well as mental health.`}</p>
    <p>{`​`}</p>
    <p>{`People who are lesbian, gay, bisexual, or transgender (LGBT) are members of every community. They are diverse, come from all walks of life, and include people of all races and ethnicities, all ages, all socioeconomic statuses, and from all parts of the country. The perspectives and needs of LGBT people should be routinely considered in public health efforts to improve the overall health of every person and eliminate health disparities. Social inequality is often associated with poorer health status, and sexual orientation has been associated with multiple health threats. Members of the LGBT community are at increased risk for a number of health threats when compared to their heterosexual peers. Differences in sexual behavior account for some of these disparities, but others are associated with social and structural inequities, such as the stigma and discrimination that LGBT populations experience. We at PCMH Restore Health are here not just to improve the health of this entire community, but also offer a culturally competent medical care and prevention services that are specific to this population.`}</p>
    <p>{`In view of the diverse health care needs of the LGBTQ community, Restore Health has taken the initiative to provide patient centered care maintaining confidentiality and without bias.  With our comprehensive services we have the expertise to deal with HIV/AIDS, sexually transmitted infections, substance abuse, physical abuse, mental health problems and other major concerns.`}</p>
    <p>{`​`}</p>
    <p>{`To strengthen our resolve in providing health care for the LGBT community, we have the people of good as view support us.`}</p>
    <p>{`​`}</p>
    <p>{`In addition to meeting the health care needs, RH organizes monthly LGBTQ meets to discuss various topics of importance such as HIV testing, PrEP/PEP, adult vaccination, LGBT laws etc`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      